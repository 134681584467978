import { AllPricesType, BalanceType } from '../../network';

import { findDirectPricePair, getFallbackPricePairs } from '.';

/**
 * Transforms a list of balances into a unique subscription list based on price data.
 *
 * @param balances - The list of balances.
 * @param priceData - The list of all price data.
 * @returns A list of unique subscriptions.
 *
 * @example
 * const priceData = [
 *   { s: 'BTCUSDT', p: '100000' },
 *   { s: 'USDCUSDT', p: '1.01' },
 *   { s: 'ETHUSDC', p: '0.0001' },
 * ];
 * const balances = [
 *   { asset: 'BTC', quote_asset: 'USDC' },
 *   { asset: 'ETH', quote_asset: 'USDC' },
 * ];
 * const subscriptionList = getPriceSubscriptionList(balances, priceData);
 * // subscriptionList will contain unique and fallback transformed pairs like ['BTCUSDT', 'USDCUSDT', 'ETHUSDC']
 */
export const getPriceSubscriptionList = (balances?: BalanceType[], priceData?: AllPricesType[]): string[] => {
  if (!balances) {
    return [];
  }

  const result = new Set<string>();

  balances.forEach(({ asset, quote_asset: quoteAsset }) => {
    const matchedPair = findDirectPricePair(asset, quoteAsset, priceData);

    if (matchedPair) {
      result.add(matchedPair);
    } else {
      // Handle fallback pairs
      const fallbacks = getFallbackPricePairs(asset, quoteAsset);

      fallbacks.some((fallback) => {
        const allFallbackPairsExist = fallback.every(({ pair: fallbackPair }) =>
          priceData?.some(({ s }) => s === fallbackPair),
        );

        if (allFallbackPairsExist) {
          fallback.forEach(({ pair: fallbackPair }) => result.add(fallbackPair));
          return true; // Exit the loop early
        }
        return false;
      });
    }
  });

  return Array.from(result);
};
