import {
  AllPricesType,
  findDirectPricePair,
  getFallbackPricePairs,
  LastPriceType,
  processFallbackPairs,
} from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';

import { BalanceType } from '../../model';

const updateBalancePrices = (
  data: BalanceType[],
  lastPrice: LastPriceType,
  allPrices?: AllPricesType[],
): BalanceType[] => {
  const clonedBalances = JSON.parse(JSON.stringify(data)) as BalanceType[];

  clonedBalances.forEach((balance) => {
    const { asset, quote_asset: quoteAsset, balance: balanceAmount } = balance;

    // If balance already has fallback, use fallback calculation directly
    if (balance.fallback) {
      updatePriceWithFallbacks(balance, lastPrice, balanceAmount);
      return;
    }

    // If pair has direct price match update it immediately
    const directPair = findDirectPricePair(asset, quoteAsset, allPrices);
    if (directPair) {
      updatePriceWithDirectMatch(balance, lastPrice, balanceAmount, directPair);
      return;
    }

    // Otherwise initialize fallbacks and use them to calculate price
    const fallbackPairs = getFallbackPricePairs(asset, quoteAsset);
    // Find the first fallback where all pairs exist in allPrices and fill in prices
    balance.fallback = fallbackPairs.find((fallbackGroup) =>
      fallbackGroup.every((fallback) => {
        const priceData = allPrices?.find(({ s }) => s === fallback.pair);
        if (priceData) {
          fallback.price = priceData.p;
          return true;
        }
        return false;
      }),
    );
    updatePriceWithFallbacks(balance, lastPrice, balanceAmount);
  });

  return clonedBalances;
};

const updatePriceWithDirectMatch = (
  balance: BalanceType,
  lastPrice: LastPriceType,
  balanceAmount: string,
  directPair: string,
) => {
  const isInverted = directPair === `${balance.quote_asset}${balance.asset}`;

  let unitPrice: string | undefined = undefined;

  if (lastPrice.s === directPair) {
    unitPrice = isInverted ? BigNumber(1).div(lastPrice.p).toString() : lastPrice.p;
  }

  if (unitPrice) {
    balance.unit_price = unitPrice;
    balance.price = BigNumber(balanceAmount).times(unitPrice).toString();
  }
};

const updatePriceWithFallbacks = (balance: BalanceType, lastPrice: LastPriceType, balanceAmount: string): boolean => {
  if (!balance.fallback) {
    return false;
  }

  const { computedPrice, isFallbackValid } = processFallbackPairs(balance.fallback, lastPrice);

  if (isFallbackValid && computedPrice) {
    balance.unit_price = computedPrice.toString();
    balance.price = BigNumber(balanceAmount).times(computedPrice).toString();
    return true;
  }

  return false;
};

export default updateBalancePrices;
