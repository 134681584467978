/**
 * Get the initials from an array of strings.
 *
 * @param {...string[]} value - The input strings.
 * @returns {string|null} The concatenated initials as a string, or null if no values are provided.
 *
 * @example
 * getInitials("Walter", "White"); // "WW"
 */
const getInitials = (...value: string[]): string | null => {
  return value.map((str) => str.charAt(0)).join('');
};

export default getInitials;
