import { CoinSymbolType } from '../../types';

type FallbackOperation = 'multiply' | 'divide';

export type FallbackDetail = {
  /** Pair of the coin symbols */
  pair: `${CoinSymbolType}${CoinSymbolType}`;
  /**
   * The operation to perform in the fallback calculation: either
   * 'multiply' or 'divide'.
   */
  operation: FallbackOperation;
  /**
   * The price associated with the pair.
   * This value can be `undefined` if the price is not yet determined.
   */
  price: string | undefined;
};

/**
 * Retrieves possible fallback price calculation pairs for a given
 * base and quote coin symbol.
 *
 * The function provides predefined fallback strategies for certain
 * coin symbols (`USDC`, `BTC`, `TRYB`). These strategies define how
 * to calculate fallback prices using intermediate pairs and operations.
 *
 * ## Why Fallbacks Are Needed?
 * In some cases, the price service does not provide a direct price
 * for a specific pair of coins (e.g., `BTCUSDC`). To address this,
 * fallback strategies are used to calculate the price indirectly
 * based on available intermediate pairs. For example:
 * - If `BTCUSDC` is unavailable, we can calculate it using:
 *   1. `BTCUSDT` price (multiply)
 *   2. `USDCUSDT` price (divide).
 *
 * This approach ensures that prices can still be derived, maintaining
 * functionality even when direct pair prices are missing.
 *
 *
 * @param base - The base coin symbol for which the fallback pairs are generated.
 * @param quote - The quote coin symbol for which the fallback pairs are generated.
 * @returns An array of fallback price calculation details grouped by strategies.
 *
 * @example
 * const fallbacks = getFallbackPricePairs('BTC', 'USDC');
 * // Example output:
 * // [
 * //   [
 * //     { pair: 'BTCUSDT', operation: 'multiply', price: undefined },
 * //     { pair: 'USDCUSDT', operation: 'divide', price: undefined },
 * //   ],
 * //   [
 * //     { pair: 'BTCTRYB', operation: 'multiply', price: undefined },
 * //     { pair: 'USDCTRYB', operation: 'divide', price: undefined },
 * //   ],
 * // ];
 */
function getFallbackPricePairs(base: CoinSymbolType, quote: CoinSymbolType) {
  if (base === quote) return [];

  const FALLBACKS: Partial<Record<CoinSymbolType, FallbackDetail[][]>> = {
    USDC: [
      [
        { pair: `${base}USDT`, operation: 'multiply', price: undefined },
        { pair: 'USDCUSDT', operation: 'divide', price: undefined },
      ],
      [
        { pair: `${base}TRYB`, operation: 'multiply', price: undefined },
        { pair: 'USDCTRYB', operation: 'divide', price: undefined },
      ],
    ],
    BTC: [
      [
        { pair: `${base}USDT`, operation: 'multiply', price: undefined },
        { pair: 'BTCUSDT', operation: 'divide', price: undefined },
      ],
      [
        { pair: `${base}TRYB`, operation: 'multiply', price: undefined },
        { pair: 'BTCTRYB', operation: 'divide', price: undefined },
      ],
    ],
    TRYB: [
      [
        { pair: `${base}USDT`, operation: 'multiply', price: undefined },
        { pair: 'USDTTRYB', operation: 'multiply', price: undefined },
      ],
      [
        { pair: `${base}USDC`, operation: 'multiply', price: undefined },
        { pair: 'USDCTRYB', operation: 'multiply', price: undefined },
      ],
    ],
  };

  return FALLBACKS[quote] || [];
}

export default getFallbackPricePairs;
