import { useCallback, useMemo, useState } from 'react';

import { useSafeLocalStorage } from '@bilira-org/design';
import {
  BalanceType,
  filterExpression,
  filterLowBalances,
  getPriceSubscriptionList,
  useDeferredFilter,
} from '@bilira-org/react-utils';

import PriceQuery from '@Libs/clientInstances/priceQuery';
import useBalanceQuoteAsset from '@Libs/hooks/useBalanceQuoteAsset';
import useGetSortedBalances from '@Libs/hooks/useGetSortedBalances';

const useMyAssetTableHook = () => {
  const { data: priceData } = PriceQuery.useGetAllPrices();

  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useSafeLocalStorage<boolean>('MYA-HSB', false);

  const { balanceQuoteAsset, isPending: isBalanceQuoteAssetPending } = useBalanceQuoteAsset();
  const { getPinnedBalance, isPending } = useGetSortedBalances({
    params: { quote_asset: balanceQuoteAsset },
    enabled: !isBalanceQuoteAssetPending,
  });

  const filteredBalanceList = useMemo(
    () => (isEnabled ? filterLowBalances(getPinnedBalance('TRYB')) : getPinnedBalance('TRYB')),
    [isEnabled, getPinnedBalance],
  );

  const filter = useCallback(
    (item: BalanceType, search: string) => filterExpression<BalanceType>(['asset', 'name'])(item, search),
    [],
  );

  const { searchText, onSearch, filteredData } = useDeferredFilter({
    predicate: filter,
    data: filteredBalanceList,
  });

  const subscriptionList = useMemo(() => getPriceSubscriptionList(filteredData, priceData), [filteredData, priceData]);

  return {
    searchText,
    onSearch,
    data: filteredData,
    isPending,
    isConversionModalOpen,
    setIsConversionModalOpen,
    isEnabled,
    setIsEnabled,
    subscriptionList,
  };
};

export default useMyAssetTableHook;
