import { CoinSymbolType, UserPreferenceType } from '@bilira-org/react-utils';

/**
 * Retrieves the value of a preference specified by a given key.
 *
 * @param {UserPreferenceType[] | undefined} data - An array of user preference objects.
 * @param {string} key - The key of the preference to retrieve.
 * @param {T} defaultValue - The default value to return if the preference is not found.
 * @returns {T} - The value of the preference, or the defaultValue if not found.
 */
const getPreferenceValue = <T>(data: UserPreferenceType[] | undefined, key: string, defaultValue: T): T => {
  return (data?.find((d) => d.key === key)?.value as T) || defaultValue;
};

/**
 * Represents a map of functions to retrieve user preferences.
 */
export const loadPreferencesMap = {
  language: (data: UserPreferenceType[]) => getPreferenceValue(data, 'language', 'tr'),
  theme: (data: UserPreferenceType[]) => {
    const theme = getPreferenceValue(data, 'theme', null);
    const isDarkModeEnabled = theme === 'system' ? null : theme === 'dark';
    return { theme, isDarkModeEnabled };
  },
  completedTours: (data: UserPreferenceType[]) => {
    const completedToursData = getPreferenceValue(data, 'completedTours', '{}') as string;
    return JSON.parse(completedToursData) as Record<string, boolean>;
  },
  balanceQuote: (data: UserPreferenceType[]) => {
    const completedToursData = getPreferenceValue<CoinSymbolType>(data, 'balance_quote', 'TRYB');
    return completedToursData;
  },
};

/**
 * Loads the user preferences from the given data.
 *
 * @param {UserPreferenceType[] | undefined} data - The user preference data to load.
 * @returns {Object} - An object containing the loaded user preferences.
 */
const loadPreferences = (data: UserPreferenceType[] | undefined) => {
  if (!data) return {};

  const language = loadPreferencesMap['language'](data);
  const { theme, isDarkModeEnabled } = loadPreferencesMap['theme'](data);
  const completedTours = loadPreferencesMap['completedTours'](data);
  const balanceQuote = loadPreferencesMap['balanceQuote'](data);

  return { language, isDarkModeEnabled, theme, completedTours, balanceQuote };
};

export default loadPreferences;
