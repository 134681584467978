import BigNumber from 'bignumber.js';

import { LastPriceType } from '../../network';

import { FallbackDetail } from '.';

/**
 * Processes fallback pairs to compute the unit price based on the fallback chain and validates the chain.
 *
 * @param {FallbackDetail[]} fallback - Array of fallback details, each containing a pair, price, and operation.
 * @param {LastPriceType} lastPrice - The latest price information, used to update matching pairs in the fallback chain.
 * @returns {{
 *   computedPrice: BigNumber | null; // The computed unit price based on the fallback chain or `null` if invalid.
 *   isFallbackValid: boolean;    // Indicates whether the fallback chain is valid (all prices are defined).
 * }}
 */
function processFallbackPairs(fallback: FallbackDetail[], lastPrice?: LastPriceType) {
  const hasLastPriceMatch = lastPrice ? fallback.some((record) => record.pair === lastPrice.s) : true;

  if (!hasLastPriceMatch) {
    return { computedPrice: null, isFallbackValid: false };
  }
  let computedPrice: BigNumber | null = BigNumber(1);

  const isFallbackValid = fallback.every((record) => {
    if (lastPrice && record.pair === lastPrice.s) {
      record.price = lastPrice.p;
    }

    if (!record.price) {
      computedPrice = null;
      return false;
    }

    computedPrice =
      record.operation === 'multiply' ? computedPrice!.times(record.price) : computedPrice!.div(record.price);

    return true;
  });

  return { computedPrice, isFallbackValid };
}

export default processFallbackPairs;
