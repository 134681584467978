import { useCallback } from 'react';

import { CoinSymbolType } from '@bilira-org/react-utils';

import PreferencesApi from '@Libs/clientInstances/preferencesQuery';
import useUserPreferences from '@Libs/hooks/useUserPreferences';

const useBalanceQuoteAsset = () => {
  const {
    preferences: { balanceQuote },
    isPending,
  } = useUserPreferences();
  const { mutateAsync, isPending: isMutating } = PreferencesApi.usePutUserPreference();

  const setBalanceQuoteAsset = useCallback(
    async (asset: CoinSymbolType) => {
      return mutateAsync({ key: 'balance_quote', value: asset });
    },
    [mutateAsync],
  );

  return { balanceQuoteAsset: balanceQuote, setBalanceQuoteAsset, isPending, isMutating };
};

export default useBalanceQuoteAsset;
