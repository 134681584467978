import React from 'react';

import { TransactionType } from '@bilira-org/react-utils';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import FiatTransactionAmount from '@Components/transaction/components/fiat/TransactionAmount';
import InternalTransactionAmount from '@Components/transaction/components/internal/TransactionAmount';
import OTCTransactionAmount from '@Components/transaction/components/otc/TransactionAmount';
import SwapTransactionAmount from '@Components/transaction/components/swap/TransactionAmount';

import { TransactionItemSizeType } from '../TransactionTable';

type TransactionTitleProps = {
  data: TransactionsRecordType;
  size?: TransactionItemSizeType;
};

const amountMap: Record<TransactionType, any> = {
  fiat: FiatTransactionAmount,
  crypto: FiatTransactionAmount,
  otc: OTCTransactionAmount,
  swap: SwapTransactionAmount,
  conversion: SwapTransactionAmount,
  other: InternalTransactionAmount,
  l2e: InternalTransactionAmount,
  payback: InternalTransactionAmount,
  promo: InternalTransactionAmount,
  marketing: InternalTransactionAmount,
};

const TransactionAmount = ({ data, size }: TransactionTitleProps) => {
  const Element = amountMap[data.type];
  if (!Element) {
    return null;
  }

  return <Element data={data} size={size} />;
};

export default TransactionAmount;
