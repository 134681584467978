import {
  AccountQueryKey,
  AllPricesType,
  BalanceType,
  OHLCSType,
  PriceQueryKey,
  updateBalancePrices,
  updateOHLCSData,
} from '@bilira-org/react-utils';

import { queryCache } from '@Libs/clientInstances/queryClient';

export const lp = (data: any) => {
  const setQuery = queryCache.setAllQueriesData([AccountQueryKey.ACCOUNT_BALANCES]);
  const allPrices = queryCache.getQueryData<AllPricesType[]>([PriceQueryKey.ALL_PRICES]);

  setQuery((priceList: unknown) => priceList && updateBalancePrices(priceList as BalanceType[], data, allPrices));
};

export const ohlc = (ohlcs: any) => {
  const priceQuery = queryCache.setQueriesData([PriceQueryKey.LIMITED_OHLCS]);
  priceQuery((ohlcData: unknown) => ohlcData && updateOHLCSData(ohlcData as OHLCSType, ohlcs));
};

export const obu = (data: any): void => {};
export const ss = (data: any): void => {};
