import {
  AccountInternalTransfersParamsType,
  CryptoTransactionsParamsType,
  FiatTransactionsParamsType,
  getIsInternalTransferType,
  OTCTransactionsParamsType,
  QueryOptions,
  SwapTransactionsParamsType,
  TransactionTypes,
} from '@bilira-org/react-utils';

import AccountApi from '@Libs/clientInstances/accountQuery';
import CryptoApi from '@Libs/clientInstances/cryptoQuery';
import FiatApi from '@Libs/clientInstances/fiatQuery';
import SwapApi from '@Libs/clientInstances/swapQuery';

type ParamTypes = {
  fiat: FiatTransactionsParamsType;
  crypto: CryptoTransactionsParamsType;
  swap: SwapTransactionsParamsType;
  otc: OTCTransactionsParamsType;
  l2e: AccountInternalTransfersParamsType;
  promo: AccountInternalTransfersParamsType;
  payback: AccountInternalTransfersParamsType;
  other: AccountInternalTransfersParamsType;
  marketing: AccountInternalTransfersParamsType;
};

interface Props<T extends TransactionTypes> {
  /** Type of the transactions */
  type: T;
  /** Parameters of the transactions query */
  params: ParamTypes[T];
  /** Query Options */
  queryOptions?: QueryOptions;
}

/**
 * Hook to fetch transactions based on transaction type.
 * @param type The type of transaction.
 * @param params The parameters for the transaction.
 * @returns The query result for the transaction type.
 */
const useGetTransactions = <T extends TransactionTypes>({
  type,
  params,
  queryOptions: { enabled = true, ...options } = { enabled: true },
}: Props<T>): any => {
  const transformedEnabled = enabled === false || !params.account ? false : undefined;
  const cryptoQuery = CryptoApi.useGetTransactions(params, {
    enabled: transformedEnabled ?? type === 'crypto',
    ...options,
  });
  const fiatQuery = FiatApi.useGetTransactions(params, { enabled: transformedEnabled ?? type === 'fiat', ...options });
  const otcQuery = SwapApi.useGetOtcTransactions(params as ParamTypes['otc'], {
    enabled: transformedEnabled ?? type === 'otc',
    ...options,
  });
  const swapQuery = SwapApi.useGetTransactions(params, {
    enabled: transformedEnabled ?? type === 'swap',
    ...options,
  });
  const internalTransfersQuery = AccountApi.useGetInternalTransfers(
    { type: type, ...params },
    {
      enabled: transformedEnabled ?? getIsInternalTransferType(type),
      ...options,
    },
  );

  switch (type) {
    case 'crypto':
      return cryptoQuery;
    case 'fiat':
      return fiatQuery;
    case 'swap':
      return swapQuery;
    case 'otc':
      return otcQuery;
    case 'l2e':
    case 'other':
    case 'payback':
    case 'promo':
    case 'marketing':
      return internalTransfersQuery;
    default:
      return undefined;
  }
};

export default useGetTransactions;
