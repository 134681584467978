import React, { ComponentProps } from 'react';

import { Block, Dropdown, Icon, Number, Skeleton, Text } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

import { useGeneralStore } from '@/store';
import useGetTotalBalance from '@Components/balance/useGetTotalBalance';
import useBalanceQuoteAsset from '@Libs/hooks/useBalanceQuoteAsset';

import FormattedNumberByPrice from '../common/FormattedNumberByPrice';

const AVAILABLE_QUOTES: CoinSymbolType[] = ['TRYB', 'USDC', 'BTC'];

interface Props {
  /** Text color of the total balance */
  color?: ComponentProps<typeof Number>['color'];
  /** Whether to show daily balance change percentage  */
  showChange24H?: boolean;
}

/**
 * Component to display total balance of the user
 *
 * Uses TRYB.free_balance property of the account balance
 *
 * @example
 * <TotalBalance color="primary-500"/>
 */
const TotalBalance = ({ color = 'secondary-500', showChange24H }: Props) => {
  const { balanceQuoteAsset, isPending, setBalanceQuoteAsset, isMutating } = useBalanceQuoteAsset();
  const { error, maskBalance, balance, isLoading } = useGetTotalBalance({ quoteAsset: balanceQuoteAsset });

  if (error || isLoading || isPending) {
    return <Skeleton height="size-4" width="size-52" mt="xl" />;
  }

  return (
    <Block row items="baseline" gap="sm">
      <FormattedNumberByPrice
        type="amount"
        price="1"
        testId="total-balance"
        animateChange={true}
        masked={maskBalance}
        heading
        size={{ xs: '3xl', sm: '3xl', md: '4xl' }}
        weight="bold"
        decimalSize={{ xs: 'xl', sm: 'xl', md: '2xl' }}
        decimalWeight="bold"
        color={color}
        value={balance}
        wordBreak="all"
      />
      <Dropdown
        color="ghost"
        appearance={false}
        listSize="92"
        value={
          <>
            <Text size={{ xs: 'base', sm: 'base', md: 'lg' }} weight="medium" font="primary" color={color}>
              {balanceQuoteAsset}
            </Text>
            <Icon type="s:chevron-up-down" color="neutral-700" />
          </>
        }
        disabled={isMutating}
        dataSource={AVAILABLE_QUOTES}
        onSelect={(item) => setBalanceQuoteAsset(item)}
        renderItem={(item) => (
          <Dropdown.Item selected={item === balanceQuoteAsset} key={item}>
            {item}
          </Dropdown.Item>
        )}
      />
      {/*{showChange24H && <PriceChange value={balances?.total_bala.change_percentage_24h} withTag size="sm" />}*/}
    </Block>
  );
};

export default TotalBalance;
