import React from 'react';

import { TransactionType } from '@bilira-org/react-utils';
import type { TransactionsRecordType } from '@bilira-org/react-utils';

import FiatTransactionStatus from '@Components/transaction/components/fiat/TransactionStatus';
import InternalTransactionStatus from '@Components/transaction/components/internal/TransactionStatus';
import OTCTransactionStatus from '@Components/transaction/components/otc/TransactionStatus';
import SwapTransactionStatus from '@Components/transaction/components/swap/TransactionStatus';

import { TransactionItemSizeType } from '../TransactionTable';

type Props = {
  data: TransactionsRecordType;
  size?: TransactionItemSizeType;
};

const amountMap: Record<TransactionType, any> = {
  fiat: FiatTransactionStatus,
  crypto: FiatTransactionStatus,
  otc: OTCTransactionStatus,
  swap: SwapTransactionStatus,
  conversion: SwapTransactionStatus,
  other: InternalTransactionStatus,
  l2e: InternalTransactionStatus,
  payback: InternalTransactionStatus,
  promo: InternalTransactionStatus,
  marketing: InternalTransactionStatus,
};

const TransactionStatus = ({ data, size }: Props) => {
  const Element = amountMap[data.type];
  if (!Element) {
    return null;
  }

  return <Element data={data} size={size} />;
};

export default TransactionStatus;
