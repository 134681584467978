import { useCallback, useMemo } from 'react';

import { BalanceParamsType, pinItems, sortListBy, SortOrder } from '@bilira-org/react-utils';

import accountQuery from '@Libs/clientInstances/accountQuery';

import useAuth from './userAuth';

interface Props {
  params?: BalanceParamsType;
  enabled?: boolean;
}
const useGetSortedBalances = (props: Props = {}) => {
  const { params = { quote_asset: 'TRYB' }, enabled = true } = props;
  const { account } = useAuth();

  const { data: balances, ...rest } = accountQuery.useGetBalances(account, params, { enabled: !!account && enabled });

  const sortedBalances = useMemo(() => (balances ? sortListBy(balances, 'price', SortOrder.DESC) : []), [balances]);

  const getPinnedBalance = useCallback(
    (pinnedAsset: string) =>
      sortedBalances
        ? pinItems({
            list: sortedBalances,
            pinnedField: 'asset',
            pinnedValue: pinnedAsset,
          })
        : [],
    [sortedBalances],
  );

  return { data: sortedBalances, getPinnedBalance, ...rest };
};

export default useGetSortedBalances;
