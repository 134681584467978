import { useCallback, useMemo, useRef } from 'react';

import { useDarkMode } from '@bilira-org/design';
import { getI18n } from 'react-i18next';

import PreferencesApi from '@Libs/clientInstances/preferencesQuery';
import loadPreferences from '@Libs/hooks/helpers/loadPreferences';
import { useSessionStore } from '@Store/sessionStore';

type Props = {
  /** Whether the query hook should be enabled. */
  enabled?: boolean;
};

/**
 * Hook for managing user preferences, such as language, theme, and completed tours.
 *
 * @param {Props} [props] - Additional properties for customizing query behavior.
 * @returns - An object containing user preferences, a function to initializing default preferences, and query states.
 */
const useUserPreferences = (props: Props = {}) => {
  const isPreferencesInitialized = useRef(false);

  const [, setEnableDarkMode] = useDarkMode();
  const setIsNotificationViewed = useSessionStore((state) => state.setIsNotificationViewed);

  const { data, ...rest } = PreferencesApi.useGetUserPreferences({
    enabled: props.enabled,
  });

  const preferences = useMemo(() => loadPreferences(data), [data]);
  const setDefaultPreferences = useCallback(() => {
    if (preferences && !isPreferencesInitialized.current) {
      setEnableDarkMode(preferences.isDarkModeEnabled);

      // Prevent unnecessary re-renders by checking if the language needs to be updated
      // Only call changeLanguage if the new language differs from the current one
      // Note: i18n triggers re-renders on language change event even when the language remains unchanged
      if (getI18n().language !== preferences.language) {
        getI18n().changeLanguage(preferences.language);
      }

      setIsNotificationViewed(false);
      isPreferencesInitialized.current = true;
    }
  }, [preferences, setEnableDarkMode, setIsNotificationViewed]);

  return {
    preferences: preferences,
    setDefaultPreferences,
    ...rest,
  } as const;
};

export default useUserPreferences;
