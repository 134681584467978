import React, { useCallback } from 'react';

import { Flex, Separator, Text } from '@bilira-org/design';
import { KeyValuePairType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import useUserPreferences from '@/libs/hooks/useUserPreferences';
import PreferencesApi from '@Libs/clientInstances/preferencesQuery';

import Language from './components/Language';
import Theme from './components/Theme';

function UserPreferences() {
  const { t } = useTranslation();
  const { mutateAsync, isPending: isMutating } = PreferencesApi.usePutUserPreference();
  const {
    preferences: { language, theme },
    isPending,
  } = useUserPreferences();

  const handleChange = useCallback(async (data: KeyValuePairType) => {
    return mutateAsync(data);
  }, []);

  return (
    <Flex width={{ xs: 'full', sm: 'full', lg: '2/4' }}>
      <Text heading size="xl" mb="md" weight="bold">
        {t('account.preferences.title')}
      </Text>

      <Language value={language || 'tr'} callback={handleChange} loading={isPending} isMutating={isMutating} />
      <Separator width="size-full" my="2xl" />
      <Theme value={theme || 'light'} callback={handleChange} loading={isPending} />
    </Flex>
  );
}

export default UserPreferences;
