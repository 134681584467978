import { TransactionsParamsType, TransactionStatusType, TransactionTypes } from '@bilira-org/react-utils';

import { VariantType } from '../components/Filters';

export const transactionDateData = [
  { key: 0, name: 'common.all' },
  { key: 1, name: 'transactions.last-1-months' },
  { key: 2, name: 'transactions.last-2-months' },
  { key: 3, name: 'transactions.last-3-months' },
  { key: 12, name: 'transactions.last-1-year' },
];

export const transactionTypeData: Record<VariantType, { data: TransactionTypes; name: string }[]> = {
  'deposit-withdraw': [
    { data: 'fiat', name: 'transactions.fiat' },
    { data: 'crypto', name: 'transactions.crypto' },
  ],
  swap: [
    { data: 'swap', name: 'transactions.swap' },
    { data: 'otc', name: 'transactions.otc' },
  ],
  other: [
    { data: 'promo', name: 'transactions.promo' },
    { data: 'l2e', name: 'transactions.l2e' },
    { data: 'marketing', name: 'transactions.marketing' },
    { data: 'other', name: 'transactions.other' },
  ],
};

export const transactionDirectionData: Partial<Record<VariantType, { data: string | undefined; name: string }[]>> = {
  'deposit-withdraw': [
    { data: undefined, name: 'common.all' },
    { data: 'withdrawal', name: 'transactions.withdrawal' },
    { data: 'deposit', name: 'transactions.deposit' },
  ],
  swap: [
    { data: undefined, name: 'common.all' },
    { data: 'buy', name: 'transactions.buy' },
    { data: 'sell', name: 'transactions.sell' },
  ],
};

/**
 * requested
 * processing: initiated, remote_timeout, remote_error,
 * completed
 * canceled: sys_canceled
 *
 */

const status: TransactionStatusType[] = ['processing', 'completed', 'canceled'];

const transactionStates = status.map((status) => ({ key: status, name: status }));

export const transactionStatusData = [{ key: undefined, name: 'all' }, ...transactionStates];

export const initialFiltersState: Record<VariantType, Omit<TransactionsParamsType, 'account'>> = {
  'deposit-withdraw': {
    offset: 0,
    status: 'completed',
  },
  other: {
    offset: 0,
    status: undefined,
  },
  swap: {
    offset: 0,
    status: 'completed',
  },
};
