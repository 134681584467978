import { BadgeColors, IconType } from '@bilira-org/design';
import { BackgroundColorType, TextColorType } from '@bilira-org/design/src/components/color';
import { OTCStatusType, TransactionStatusType } from '@bilira-org/react-utils';

export const iconMap: Record<string, IconType> = {
  'fiat:deposit': 'o:arrow-down-tray',
  'fiat:withdrawal': 'o:arrow-up-tray',
  'crypto:deposit': 'o:arrow-down-tray',
  'crypto:withdrawal': 'o:arrow-up-tray',
  'crypto:promo': 'o:gift',
  'swap:swap': 'o:arrows-right-left',
  'conversion:conversion': 'o:arrow-path',
  'otc:swap': 'o:hand-coins',
  'promo:incoming': 'o:arrow-down-tray',
  'promo:outgoing': 'o:arrow-up-tray',
  'payback:incoming': 'o:arrow-down-tray',
  'payback:outgoing': 'o:arrow-up-tray',
  'other:incoming': 'o:arrow-down-tray',
  'other:outgoing': 'o:arrow-up-tray',
  'l2e:incoming': 'o:arrow-down-tray',
  'l2e:outgoing': 'o:arrow-up-tray',
  'marketing:incoming': 'o:arrow-down-tray',
  'marketing:outgoing': 'o:arrow-up-tray',
};

const refundStatuses: TransactionStatusType[] = [
  'refund_initiated',
  'refund_processing',
  'refund_requested',
  'refunded',
];

export type IconMapType = keyof typeof iconMap;

/**
 * Gets txn status config and style by txn status.
 *
 * @param {TransactionStatusType} key - The transaction status key.
 * @returns {{
 *   icon: (type: IconMapType) => IconType,
 *   text: TextColorType,
 *   bg: BackgroundColorType
 * }} - The configuration object containing icon, text color, and background color.
 *
 * @example
 * getStateConfig('requested');
 * // { icon: (type: IconMapType) => IconType, text: 'yellow-500', bg: 'yellow-50' }
 */
export const getStateConfig = (
  key: TransactionStatusType,
): {
  icon: (type: IconMapType) => IconType;
  text: TextColorType;
  bg: BackgroundColorType;
} => {
  return {
    icon: refundStatuses.includes(key) ? () => 'o:arrow-u-turn-left' : (type) => iconMap[type],
    text: TRANSACTION_STATUS[key]?.text,
    bg: TRANSACTION_STATUS[key]?.bg as BackgroundColorType,
  };
};

export const TRANSACTION_STATUS: {
  [key in TransactionStatusType]: {
    icon: IconType;
    text: TextColorType;
    bg: string;
  };
} = {
  requested: { icon: 'o:arrow-up-tray', text: 'yellow-500', bg: 'yellow-50' },
  initiated: { icon: 'o:arrow-up-tray', text: 'primary-500', bg: 'primary-50' },
  pending_approval: { icon: 'o:arrow-up-tray', text: 'primary-500', bg: 'primary-50' },
  remote_timeout: { icon: 'o:arrow-down-tray', text: 'primary-500', bg: 'primary-50' },
  remote_error: { icon: 'o:arrow-down-tray', text: 'primary-500', bg: 'primary-50' },
  unmatched: { icon: 'o:arrow-down-tray', text: 'primary-500', bg: 'primary-50' },
  processing: { icon: 'o:arrow-down-tray', text: 'primary-500', bg: 'primary-50' },
  completed: { icon: 'o:arrow-down-tray', text: 'green-500', bg: 'green-50' },
  refunded: { icon: 'o:arrow-down-tray', text: 'green-500', bg: 'green-50' },
  canceled: { icon: 'o:arrow-down-tray', text: 'red-500', bg: 'red-50' },
  sys_canceled: { icon: 'o:arrow-up-tray', text: 'red-500', bg: 'red-50' },
  failed: { icon: 'o:arrow-up-tray', text: 'red-500', bg: 'red-50' },
  refund_requested: { icon: 'o:arrow-u-turn-left', text: 'yellow-500', bg: 'yellow-50' },
  refund_initiated: { icon: 'o:arrow-u-turn-left', text: 'primary-500', bg: 'primary-50' },
  refund_processing: { icon: 'o:arrow-u-turn-left', text: 'orange-500', bg: 'orange-50' },
};

export const OTC_TRANSACTION_STATUS: {
  [key in OTCStatusType]: {
    badgeBg: BadgeColors;
    icon: IconType;
    text: TextColorType;
    bg: string;
  };
} = {
  failed: { badgeBg: 'orange', icon: 'o:arrow-up-tray', text: 'orange-500', bg: 'orange-50' },
  accepted: { badgeBg: 'primary', icon: 'o:arrow-down-tray', text: 'primary-500', bg: 'primary-50' },
  pending: { badgeBg: 'orange', icon: 'o:arrow-down-tray', text: 'orange-500', bg: 'orange-50' },
  expired: { badgeBg: 'red', icon: 'o:arrow-down-tray', text: 'red-500', bg: 'red-50' },
  completed: { badgeBg: 'green', icon: 'o:arrow-down-tray', text: 'green-500', bg: 'green-50' },
  canceled: { badgeBg: 'red', icon: 'o:arrow-down-tray', text: 'red-500', bg: 'red-50' },
  rejected: { badgeBg: 'red', icon: 'o:arrow-up-tray', text: 'red-500', bg: 'red-50' },
};

/**
 * Gets OTC txn status config and style by OTC txn status.
 *
 * @param {OTCStatusType} key - The OTC transaction status key.
 * @returns {{
 *   icon: (type: IconMapType) => IconType,
 *   text: TextColorType,
 *   bg: BackgroundColorType
 * }} - The configuration object containing icon, text color, and background color.
 *
 *  @example
 * getOTCStatusConfig('failed');
 * // { badgeBg: 'orange', icon: (type: IconMapType) => IconType, text: 'orange-500', bg: 'orange-50' }
 */
export const getOTCStatusConfig = (
  key: OTCStatusType,
): {
  badgeBg: BadgeColors;
  icon: (type: IconMapType) => IconType;
  text: TextColorType;
  bg: BackgroundColorType;
} => {
  return {
    badgeBg: OTC_TRANSACTION_STATUS[key]?.badgeBg,
    icon: (type: IconMapType) => iconMap[type],
    text: OTC_TRANSACTION_STATUS[key]?.text,
    bg: OTC_TRANSACTION_STATUS[key]?.bg as BackgroundColorType,
  };
};
