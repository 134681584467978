export const INTERNAL_TRANSFER_TYPES = ['promo', 'other', 'payback', 'l2e', 'marketing'];

/**
 * Determines whether the specified transfer type is an internal transfer.
 * This function is used in the transaction detail modal to request transaction details
 * or internal transfer details based on the transfer type.
 * @param {string} type - The transfer type to check.
 * @returns {boolean} - True if the transfer type is internal, otherwise false.
 *
 * @example
 * getIsInternalTransferType('swap') // false
 * getIsInternalTransferType('promo') // true
 */
function getIsInternalTransferType(type?: string): boolean {
  if (!type) return false;
  return INTERNAL_TRANSFER_TYPES.includes(type);
}

export default getIsInternalTransferType;
