import React, { ReactNode } from 'react';

import { Skeleton, TextProps } from '@bilira-org/design';
import { JustifyType } from '@bilira-org/design/src/components/types';
import { concatTicker } from '@bilira-org/react-utils';

import PriceChange from '@Components/common/PriceChange';
import PriceQuery from '@Libs/clientInstances/priceQuery';
import useStatisticsLive from '@Libs/hooks/useStatisticsLive';
import EmptyText from '@Modules/settings/commission/fee/components/EmptyText';

type Props = {
  base?: string;
  quote?: string;
  size?: TextProps['size'];
  justify?: JustifyType;
  weight?: TextProps['weight'];
  skeleton?: ReactNode;
  value?: string;
};
const H24ChangeLive = ({ base, quote, size, justify = 'start', value, weight, skeleton }: Props) => {
  const { liveData } = useStatisticsLive({ symbol: concatTicker(base, quote) });

  // Should be deprecated soon. @sulhadin
  const { data } = PriceQuery.useGetSymbolStatistics(concatTicker(base, quote), { enabled: !value });

  if (base === 'BMMF') {
    return <EmptyText />;
  }

  const change24h = liveData?.change_24 ?? value ?? data?.change_24;

  if (!change24h) {
    if (skeleton) {
      return skeleton;
    }
    return <Skeleton key="price" height="size-4" width="size-24" />;
  }

  return <PriceChange justify={justify} size={size} weight={weight} value={change24h} />;
};

export default H24ChangeLive;
