import { AllPricesType } from '../../network';
import { CoinSymbolType } from '../../types';

/**
 * Checks if a direct price exists between two assets and returns the matched pair.
 *
 * @param asset - The base asset.
 * @param quoteAsset - The quote asset.
 * @param allPrices - The list of all available prices.
 * @returns The matched pair string if a direct price exists, otherwise `null`.
 */
const findDirectPricePair = (
  asset: CoinSymbolType,
  quoteAsset: CoinSymbolType,
  allPrices?: AllPricesType[],
): string | null => {
  const potentialPairs = [`${asset}${quoteAsset}`, `${quoteAsset}${asset}`];

  const matchedPair = potentialPairs.find((pair) => allPrices?.some(({ s }) => s === pair));

  return matchedPair || null;
};

export default findDirectPricePair;
